import { Text, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { Image, Link } from '@sitecore-jss/sitecore-jss-react';
import { ComponentProps } from 'lib/component-props';
import { PSP } from 'models/PetSuppliesPlus.Model';
import React from 'react';
import withPersonalization from 'src/helpers/withPersonalization/withPersonalization';
type PLPMarketingTileData =
  PSP.Sitecore.templates.PetSuppliesPlus.MarketingTile.Fields.PlpMarketingTileItem & ComponentProps;
const PLPMarketingTile = ({ fields }: PLPMarketingTileData) => {
  //This is just for an Experience Editor, we are not gonna use in frontend site
  return (
    <div>
      <div>
        columnPosition: <Text field={fields?.columnPosition} />
      </div>
      <div>
        columnSpan: <Text field={fields?.columnSpan} />
      </div>
      <div>
        rowReplacement: <Text field={fields?.rowReplacement} />
      </div>
      <div>
        mobileRowReplacement: <Text field={fields?.mobileRowReplacement} />
      </div>
      <div>
        text: <Text field={fields?.text} />
      </div>
      <div>
        link:
        <Link field={fields?.link || { value: { href: '/' } }} />
      </div>
      <div>
        tileImage:
        <Image field={fields?.tileImage} />
      </div>
      <div>
        mobileTileImage:
        <Image field={fields?.mobileTileImage} />
      </div>
    </div>
  );
};

// export default PLPMarketingTile;
export default withDatasourceCheck()<PLPMarketingTileData>(withPersonalization(PLPMarketingTile));
