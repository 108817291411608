//global
import React, { Ref, useContext, useEffect, useRef, useState } from 'react';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { Me, Tokens } from 'ordercloud-javascript-sdk';
import { Form as FormikForm, Formik, FormikValues, FormikHelpers, FormikProps } from 'formik';
import _ from 'lodash';
//local
import {
  MyAccountDetailsProps,
  allValuesAreEmpty,
} from 'components/MyAccountDetails/MyAccountDetails';
import useDictionary from 'src/hooks/useDictionary';
import { listAddresses, saveAddress } from 'src/redux/ocAddressBook';
import { useOcDispatch } from 'src/redux/ocStore';
import TextHelper from 'src/helpers/commonComponents/TextHelper';
import { transformData } from 'src/utils/formUtils';
import TextField from 'src/helpers/Form/TextField';
import { Country, addressType } from 'src/helpers/Constants';
import DropdownField from 'src/helpers/Form/DropdownField';
import AutoCompleteWrapper from 'src/helpers/Form/AutoCompleteWrapper';
//lib
import ComponentContext from 'lib/context/ComponentContext';
import PoweredByGoogleImage from 'src/helpers/google/PoweredByGoogleImage/PoweredByGoogleImage';
import Loader from 'components/Loader/Loader';
import { unwrapResult } from '@reduxjs/toolkit';
import { myAccountDetailsTailwindVariant } from 'tailwindVariants/components/myAccountDetailsTailwindVariant';
//type
export type MyAccountBillingAddressProps = MyAccountDetailsProps;
interface FormValuesTypes {
  FirstName?: string;
  LastName?: string;
  AddressLine1: string;
  AddressLine2: string;
  City: string;
  State: string;
  Zip: string;
}
//main component
const MyAccountBillingAddress: React.FC<MyAccountBillingAddressProps> = ({ fields, params }) => {
  const {
    base,
    headigWrapper,
    textLabel,
    smallLabel,
    content,
    editingContent,
    inlineFields,
    informationContainer,
    fieldWrapper,
    userInfo,
    linkText,
    submitBtn,
    cancelBtn,
    loaderWrapper,
    textLabelBold,
    textLabelMedium,
    googleImageWrapper,
  } = myAccountDetailsTailwindVariant({
    size: { initial: 'mobile', lg: 'desktop' },
  });

  // transforming the form field data
  const transFormFields = transformData(fields.data?.data?.formFields);
  const { getDictionaryValue } = useDictionary();
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const dispatch = useOcDispatch();
  const [address, settAddress] = useState<FormValuesTypes>();
  // const [isDiliveryAddress, setIsDiliveryAddress] = useState(false);
  const [addressId, setAddressId] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [fieldValues, setFieldValues] = useState<FormValuesTypes>();
  const formRef = useRef<FormikHelpers<FormValuesTypes>>(null);
  const activeSection = fields?.data?.data?.title?.value;

  //fetching initial values.
  const setInitialValues = async () => {
    setLoading(true);
    const listAdd = await dispatch(listAddresses({}));

    const addresses = unwrapResult(listAdd);
    const filteredAddress = addresses?.Items?.filter((item) => {
      return item?.AddressName === addressType?.billing;
    });

    settAddress({
      FirstName: filteredAddress && filteredAddress?.[0]?.FirstName,
      LastName: filteredAddress && filteredAddress?.[0]?.LastName,
      AddressLine1: filteredAddress?.[0]?.Street1 ?? '',
      AddressLine2: filteredAddress?.[0]?.Street2 ?? '',
      City: filteredAddress?.[0]?.City ?? '',
      State: filteredAddress?.[0]?.State ?? '',
      Zip: filteredAddress?.[0]?.Zip ?? '',
    });

    setAddressId(filteredAddress && filteredAddress[0]?.ID);
    setLoading(false);
  };

  //load initial data
  useEffect(() => {
    setInitialValues();
  }, [componentContextData?.currentSection]);

  //initial values for the form
  const initialValues: FormValuesTypes = {
    FirstName: address?.FirstName || '',
    LastName: address?.LastName || '',
    AddressLine1: address?.AddressLine1 || '',
    AddressLine2: address?.AddressLine2 || '',
    City: address?.City || '',
    State: address?.State || '',
    Zip: address?.Zip || '',
  };

  /**chacking the initial values if all values are empty or not */
  const isValuesEmpty = allValuesAreEmpty(initialValues);

  //maping the auto complete address fields
  const autoCompleteFieldMap = {
    addressLine1: 'AddressLine1',
    addressLine2: 'AddressLine2',
    city: 'City',
    stateCode: 'State',
    zipcode: 'Zip',
  };

  //comparing the form value object
  useEffect(() => {
    const isEqual = _.isEqual(initialValues, fieldValues);
    isEqual
      ? setcomponentContextData({ ...componentContextData, isEqualFields: true })
      : setcomponentContextData({ ...componentContextData, isEqualFields: false });
  }, [fieldValues]);

  //handling accordian
  useEffect(() => {
    const item = document?.querySelectorAll('.myAccountDetails');
    item.forEach((item) => {
      const content = item.querySelector<HTMLElement>('.billingContent');
      const form = item.querySelector<HTMLElement>('.billingEditContent');
      if (item.classList.contains('open')) {
        if (componentContextData?.collapse === fields?.data?.data?.title?.value) {
          form ? (form.style.maxHeight = '1000px') : '';
          content ? (content.style.maxHeight = '0px') : '';
        } else {
          form ? (form.style.maxHeight = `0px`) : '';
          content ? (content.style.maxHeight = `${200}px`) : '';
        }
      }
    });
  }, [componentContextData?.collapse]);

  //handling collapse action
  const handleCollapse = (title: string) => {
    setcomponentContextData({
      ...componentContextData,
      isEqualFields: true,
    });
    componentContextData?.isEqualFields
      ? setcomponentContextData({ ...componentContextData, collapse: title, isEqualFields: true })
      : setcomponentContextData({ ...componentContextData, collapse: title, isEqualFields: false });
  };

  //handling the values on onChange
  const setValues = (values: FormValuesTypes) => {
    setFieldValues({
      ...values,
    });
  };

  //prefill data from the delivery address
  const prefillAddress = async () => {
    const listAdd = await Me.ListAddresses();
    const filteredAddress = listAdd?.Items.filter((item) => {
      return item?.AddressName === addressType?.delivery;
    });
    formRef?.current?.setValues({
      FirstName: filteredAddress && filteredAddress[0]?.FirstName,
      LastName: filteredAddress && filteredAddress[0]?.LastName,
      AddressLine1: filteredAddress && filteredAddress[0]?.Street1,
      AddressLine2: filteredAddress && filteredAddress[0]?.Street2,
      City: filteredAddress && filteredAddress[0]?.City,
      State: filteredAddress && filteredAddress[0]?.State,
      Zip: filteredAddress && filteredAddress[0]?.Zip,
    });
  };

  //handling submit action
  const submitFormData = async (values: FormikValues) => {
    try {
      setSaveLoading(true);
      const billingAddress = {
        FirstName: values?.FirstName,
        LastName: values?.LastName,
        City: values?.City,
        Country: Country,
        Billing: true,
        State: values?.State,
        Street1: values?.AddressLine1,
        Street2: values?.AddressLine2,
        Zip: values?.Zip,
        AddressName: addressType?.billing,
      };
      const accessToken = Tokens?.GetAccessToken();
      if (isValuesEmpty) {
        const res = await Me.CreateAddress(billingAddress, {
          accessToken,
        });
        if (res) {
          setInitialValues();
          setcomponentContextData({ ...componentContextData, isEqualFields: true, collapse: null });
        }
      } else {
        const address = {
          ID: addressId,
          ...billingAddress,
        };
        const res = await dispatch(saveAddress(address));
        if (res) {
          setInitialValues();
          setcomponentContextData({ ...componentContextData, isEqualFields: true, collapse: null });
        }
      }
      setSaveLoading(false);
    } catch (error) {
      console.error('@@BillingAddress: ', error);
    }
  };

  //if no data then returned empty fragment
  if (fields === undefined || fields === null) return <></>;

  return (
    <div className={base({ className: params?.Style + ' open' ?? '' })}>
      <div className={content({ className: 'billingContent' })}>
        <div className={headigWrapper()}>
          <TextHelper tag="p" field={fields?.data?.data?.title} className={textLabelMedium()} />
          {/**TODO: replace with dynamic text */}
          <button
            aria-label="add/edit"
            aria-controls="button"
            className={linkText()}
            onClick={() => {
              formRef?.current?.resetForm();
              handleCollapse(activeSection);
              !componentContextData?.isEqualFields &&
                setcomponentContextData({
                  ...componentContextData,
                  isWarningPopUp: true,
                  passwordChangeSuccess: false,
                  currentSection: activeSection,
                });
            }}
          >
            {isValuesEmpty ? getDictionaryValue('AddCTA') : getDictionaryValue('EditCTA')}
          </button>
        </div>
        {!isValuesEmpty && !saveLoading && (
          <div className={userInfo()}>
            <p className={textLabel()}>
              {address?.FirstName}&nbsp;{address?.LastName}
            </p>
            <p className={textLabel()}>{address?.AddressLine1}&nbsp;</p>
            <p className={textLabel()}>{address?.AddressLine2 ?? ''}</p>
            <p className={textLabel()}>
              {address?.City}, {address?.State + ' ' + address?.Zip ?? ''}
            </p>
          </div>
        )}
      </div>
      <div
        className={editingContent({
          className: `billingEditContent ${
            componentContextData?.collapse === fields?.data?.data?.title?.value ? '' : '!py-0'
          }`,
        })}
      >
        <div className={headigWrapper({ className: 'flex-col lg:flex-row' })}>
          <TextHelper tag="p" field={fields?.data?.data?.title} className={textLabelBold()} />
          {componentContextData?.isMyAccountDeliveryAddress === true && (
            <button
              aria-label="prefill delivery address"
              className={textLabelMedium({
                className: linkText(),
              })}
              onClick={prefillAddress}
            >
              {getDictionaryValue('PrefillDeliveryAddress')}
            </button>
          )}
        </div>
        <TextHelper tag="p" field={fields?.data?.data?.shortDescription} className={smallLabel()} />
        {!loading && (
          <Formik
            initialValues={initialValues}
            innerRef={formRef as Ref<FormikProps<FormValuesTypes>>}
            onSubmit={(values: FormValuesTypes) => {
              submitFormData(values);
            }}
          >
            {({ resetForm }) => (
              <FormikForm className={informationContainer()}>
                <div className={inlineFields()}>
                  {transFormFields?.FirstName && (
                    <div className={fieldWrapper()}>
                      <TextField {...transFormFields?.FirstName} setFieldValue={setValues} />
                    </div>
                  )}
                  {transFormFields?.LastName && (
                    <div className={fieldWrapper()}>
                      <TextField {...transFormFields?.LastName} setFieldValue={setValues} />
                    </div>
                  )}
                </div>
                <div className={inlineFields()}>
                  <AutoCompleteWrapper fieldMap={autoCompleteFieldMap} setFieldValue={setValues}>
                    {/* eslint-disable-next-line @typescript-eslint/no-explicit-any*/}
                    {({}: any) => {
                      return (
                        <>
                          <TextField {...transFormFields.AddressLine1} />
                        </>
                      );
                    }}
                  </AutoCompleteWrapper>
                  {transFormFields?.AddressLine2 && (
                    <div className={fieldWrapper()}>
                      <TextField {...transFormFields?.AddressLine2} setFieldValue={setValues} />
                    </div>
                  )}
                </div>
                <div className={inlineFields()}>
                  {transFormFields?.City && (
                    <div className={fieldWrapper()}>
                      <TextField {...transFormFields?.City} setFieldValue={setValues} />
                    </div>
                  )}
                  {transFormFields.State && (
                    <div className={fieldWrapper()}>
                      <DropdownField
                        {...transFormFields.State}
                        options={fields?.data?.city?.regions?.targetItems}
                        firstOptionData={getDictionaryValue('SelectStateOptionLabel') as string}
                      />
                    </div>
                  )}
                </div>
                <div className={inlineFields()}>
                  {transFormFields?.Zip && (
                    <div className={fieldWrapper()}>
                      <TextField {...transFormFields?.Zip} setFieldValue={setValues} />
                    </div>
                  )}
                  <div className={fieldWrapper({ className: 'hidden lg:block' })}></div>
                </div>
                <div className={inlineFields()}>
                  {saveLoading ? (
                    <div className={loaderWrapper()}>
                      <Loader />
                      {getDictionaryValue('Saving')}
                    </div>
                  ) : (
                    <>
                      {fields.data?.data?.submitButtonText?.value && (
                        <button aria-label="submit" className={submitBtn()} type="submit">
                          <TextHelper field={fields.data?.data?.submitButtonText} />
                        </button>
                      )}
                      {fields.data?.data?.cancelButtonText?.value && (
                        <button
                          aria-label="reset"
                          type="reset"
                          className={cancelBtn()}
                          onClick={() => {
                            resetForm;
                            setFieldValues(initialValues);
                            setcomponentContextData({ ...componentContextData, collapse: null });
                          }}
                        >
                          <TextHelper field={fields.data?.data?.cancelButtonText} />
                        </button>
                      )}
                    </>
                  )}
                </div>
              </FormikForm>
            )}
          </Formik>
        )}
        <div className={googleImageWrapper()}>
          <PoweredByGoogleImage />
        </div>
      </div>
    </div>
  );
};

//check withDataSourceCheck If it is not then show blank instead of error.
export default withDatasourceCheck()<MyAccountBillingAddressProps>(MyAccountBillingAddress);
