/* eslint-disable @typescript-eslint/no-explicit-any */
//global
import React, { useEffect, useRef, useState } from 'react';
import { RichText, Text, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { Splide, SplideSlide } from '@splidejs/react-splide';

//helpers
// import SplideSlider from 'src/helpers/commonComponents/SplideSlider';
import ImageHelper from 'src/helpers/commonComponents/ImageHelper';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';
import TextHelper from 'src/helpers/commonComponents/TextHelper';
import IconHelper from 'src/helpers/commonComponents/IconHelper';

//lib
import { ComponentProps } from 'lib/component-props';
import { useGraphQlDataContext } from 'lib/context/GraphQlDataContext';
import { useOcSelector } from 'src/redux/ocStore';
import { PSP } from 'models/PetSuppliesPlus.Model';

//tailwindVariants
import { servicePanelVariants } from 'tailwindVariants/components/servicePanelTailwindVariants';
import { EventInfoGql } from 'src/pages/api/store/get-store-details';
import { useBreakpoints } from 'src/utils/breakpoints';
import {
  formatDateForGTM,
  Items,
  notAvailableIfNullOrEmpty,
  trackObjectForPromotion,
} from 'src/utils/sendGTMEvent';
import { GTM_EVENT } from 'src/helpers/Constants';

//type
export type ServicePanelProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.Release2.ServicePanel.Fields.ServicePanel;

//main component
const ServicePanel: React.FC<ServicePanelProps> = (props) => {
  //if no data then returned empty fragment
  if (props?.fields === undefined || props?.fields === null) return <></>;

  return (
    <React.Fragment>
      <ServiceCarousel {...props} />
      <Events {...props} />
    </React.Fragment>
  );
};

//ServicePanel Carousel
const ServiceCarousel: React.FC<ServicePanelProps> = ({ fields }) => {
  const {
    base,
    panelWrapper,
    carouselWrapper,
    panelDetails,
    panelHeading,
    panelDescription,
    panelContent,
    singleSlide,
  } = servicePanelVariants({
    size: { initial: 'mobile', lg: 'desktop' },
  });

  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);
  const singleSliderRef = useRef<Splide | null>(null);
  const secondarySliderRef = useRef<Splide | null>(null);
  const { isMobile, isDesktop, screenWidth } = useBreakpoints();

  //current store data
  const storeData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const storeServices = useGraphQlDataContext();
  const currentStoreServices = storeData?.services;
  const currentStoreServicesArray = currentStoreServices ? currentStoreServices.split('|') : [];
  const selectedService = currentStoreServicesArray?.map(
    (storeServiceId) =>
      storeServices &&
      storeServices?.storeServices?.find(
        (service) => service?.serviceId?.value?.toString() === storeServiceId
      )
  );

  //default Image for store manager/lead if image is not available in dataSource
  const defaultManagerImage = useOcSelector(
    (state) => state?.otherSettingSlice?.data?.defaultStoreManagerImage
  );

  //employees role from global settings(other settings)
  const globalEmployeeRoles = useOcSelector(
    (state) => state?.otherSettingSlice?.data?.storeLeadRoles
  );

  //getting role field to filter employees from current store based on globalEmployeeRoles
  const roleTitlesToFilter = globalEmployeeRoles?.jsonValue.map(
    (role) => role?.fields?.title?.value
  );

  // Filter employees based on role titles
  const filteredEmployees = storeData?.storeEmployees?.filter((employee) => {
    const employeeRoleTitle = employee?.role?.jsonValue?.fields?.title?.value;
    const employeeShouldVisible = employee?.showInServicePanel?.jsonValue?.value;

    return roleTitlesToFilter?.includes(employeeRoleTitle) && employeeShouldVisible;
  });

  //helper functions to transform data for carousel Items.
  const transformData = (data: typeof selectedService | typeof filteredEmployees) => {
    return data?.map((item) => {
      if (!item) return null;
      return {
        title: 'name' in item ? item?.name : 'employeeName' in item ? item.employeeName : '',
        subHeading: undefined, // Default value since it's not in the input
        description:
          'description' in item
            ? item?.description
            : 'role' in item
            ? item?.role?.jsonValue?.fields?.title
            : '',
        image:
          'servicePanelImage' in item
            ? item?.servicePanelImage
            : 'photo' in item
            ? item?.photo?.jsonValue?.value?.src
              ? item?.photo
              : defaultManagerImage
            : '',
        cta: 'link' in item ? item?.link : null,
      };
    });
  };

  //Employees final data after filtering
  const employeesArray = transformData(filteredEmployees);

  //transforming data for services tile
  const cards = transformData(
    selectedService?.sort(
      (a, b) => (a?.servicePanelOrder?.value || 0) - (b?.servicePanelOrder?.value || 0)
    )
  );

  //combining employee data and service data for carousel, Manager will be first item.
  const serviceCarouselTiles = [
    ...((fields?.showStoreManager?.value && employeesArray) || []),
    ...Object.values(cards),
  ];

  const showArrows = serviceCarouselTiles?.length > 3;

  //syncing both slider
  useEffect(() => {
    isDesktop &&
      secondarySliderRef?.current?.splide?.Components?.Controller?.setIndex(activeSlideIndex + 1);
    if (activeSlideIndex === cards?.length - (screenWidth && screenWidth < 1312 ? 3 : 4)) {
      singleSliderRef?.current?.splide?.Components?.Arrows?.arrows?.next?.setAttribute(
        'disabled',
        'true'
      );
    }
  }, [activeSlideIndex]);

  const sendGtmData = (index: number) => {
    const obj = [];
    obj?.push(serviceCarouselTiles && serviceCarouselTiles[index]);
    serviceCarouselTiles &&
      trackObjectForPromotion(GTM_EVENT?.selectPromotion, storeData?.storeId, obj as Items[]);
  };

  //sending view_promotion data on page load
  useEffect(() => {
    if (storeData?.storeId && serviceCarouselTiles) {
      trackObjectForPromotion(
        GTM_EVENT?.viewPromotion,
        storeData?.storeId,
        serviceCarouselTiles as Items[]
      );
    }
  }, [storeData?.storeId]);

  return (
    <section className={base()} component-data="components/ServicePanel/ServicePanel">
      <div className={panelContent()}>
        {/**Single Slide  hiding on mobile devices*/}
        {!isMobile && isDesktop && (
          <div className={singleSlide()}>
            <Splide
              ref={singleSliderRef}
              options={{
                pagination: false,
                perMove: 1,
                perPage: 1,
                speed: 0,
                gap: '24px',
                width: '404px',
                fixedWidth: '404px',
                arrows: showArrows,
                classes: {
                  arrows: 'inset-0',
                  prev: 'arrow-prev translate-x-0 splide-button splide__arrow--prev',
                  next: 'arrow-next splide-button splide__arrow--next',
                },
              }}
              onMoved={(_currentIndex, newIndex) => {
                secondarySliderRef?.current?.splide?.Components?.Move?.move(
                  newIndex,
                  newIndex + 1,
                  0
                );
                setActiveSlideIndex(newIndex);
              }}
              className={'!static h-full [&>*:nth-child(even)]:h-full'}
            >
              {serviceCarouselTiles?.map((card: any, index: number) => {
                return (
                  <SplideSlide key={index}>
                    <ServiceTile
                      onClick={() => {
                        sendGtmData(index);
                      }}
                      card={card}
                      key={index}
                      index={index}
                      tileLength={cards?.length}
                    />
                  </SplideSlide>
                );
              })}
            </Splide>
          </div>
        )}

        {/** Three slide carousel */}
        <div className={panelWrapper()}>
          <div className={panelDetails()}>
            <RichTextHelper
              updatedField={{
                value:
                  fields?.title?.value?.replace('${store-name}', storeData?.storeName ?? '') || '',
              }}
              field={fields?.title}
              className={panelHeading()}
            />
            <RichText tag={'p'} field={fields?.description} className={panelDescription()} />
          </div>
          <div className={carouselWrapper()}>
            {screenWidth && (
              <Splide
                ref={secondarySliderRef}
                options={{
                  pagination: false,
                  drag: false,
                  perMove: 1,
                  gap: isMobile ? '16px' : '24px',
                  arrows: false,
                  speed: 0,
                  start: screenWidth && screenWidth > 1023 ? 1 : 0,
                  breakpoints: {
                    1044: {
                      perPage: 3,
                    },
                    1023: {
                      perPage: 2,
                    },
                  },
                }}
                className="md:max-w-[600px]lg:max-w-[816px] [&>div]:!overflow-scroll [&>div]:lg:!overflow-hidden"
              >
                {serviceCarouselTiles?.map((card: any, index: number) => {
                  return (
                    <SplideSlide key={index}>
                      <ServiceTile
                        onClick={() => {
                          sendGtmData(index);
                        }}
                        card={card}
                        key={index}
                        index={index}
                        tileLength={serviceCarouselTiles?.length}
                      />
                    </SplideSlide>
                  );
                })}
              </Splide>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

//UpcomingEvents
const Events: React.FC<ServicePanelProps> = ({ fields }) => {
  const {
    base,
    eventHeading,
    eventWrapper,
    eventsTiles,
    marketingTiles,
    eventBorder,
    eventAndTileWrapper,
  } = servicePanelVariants({
    size: { initial: 'mobile', lg: 'desktop' },
  });

  const storeData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const eventsData = storeData?.storeEvents;
  const { deviceName } = useBreakpoints();

  const getMarketingGridCols = (eventCount: number) => {
    if (eventCount === 1) return 'lg:col-span-2';
    if (eventCount === 2) return 'lg:col-span-1';
    return 'lg:col-span-3';
  };

  const getEventGridCols = (eventCount: number) => {
    if (eventCount === 1) return 'lg:col-span-1';
    if (eventCount === 2) return 'lg:col-span-2';
    return 'lg:col-span-3';
  };

  const parseDateTime = (dateTimeObj: EventInfoGql) => {
    const [datePart, timePart] = dateTimeObj?.fromDateTime?.value.split(' ');
    const [month, day, year] = datePart.split('/');

    // Ensure zero-padding for single-digit months and days
    const formattedMonth = month.padStart(2, '0');
    const formattedDay = day.padStart(2, '0');
    const formattedTime = timePart.includes(':') ? timePart : '00:00';

    // Create date string in ISO format
    const dateString = `${year}-${formattedMonth}-${formattedDay}T${formattedTime}:00`;
    const parsedDate = new Date(dateString);

    // Validate the date
    if (isNaN(parsedDate.getTime())) {
      console.error('Invalid date:', dateTimeObj.fromDateTime.value);
      return new Date(0); // Return epoch start as a fallback
    }

    return parsedDate;
  };

  const sortEventsByDateTime = (events: EventInfoGql[]) => {
    return [...events]?.sort((a, b) => {
      const dateA = parseDateTime(a);
      const dateB = parseDateTime(b);

      // If dates are the same, comparing time
      if (dateA.getTime() === dateB.getTime()) {
        const timeA = a.fromDateTime.value.split(' ')[1];
        const timeB = b.fromDateTime.value.split(' ')[1];
        return timeA.localeCompare(timeB);
      }

      return dateA?.getTime() - dateB?.getTime();
    });
  };

  const sortedEventsData = sortEventsByDateTime(eventsData);

  const marketingTilesToShow = eventsData?.length > 2 ? 0 : 3 - eventsData?.length;

  const gtmPromotion = fields?.marketingTile?.slice(0, marketingTilesToShow).map((tile) => {
    return {
      promotion_id: notAvailableIfNullOrEmpty(tile?.fields?.promotionId?.value),
      promotion_name: notAvailableIfNullOrEmpty(tile?.fields?.promotionName?.value),
      creative_name: notAvailableIfNullOrEmpty(tile?.fields?.componentName?.value),
      creative_slot: Number(tile?.fields?.creativeSlotNumber?.value),
      promotion_device: deviceName,
      promotion_copy: notAvailableIfNullOrEmpty(tile?.fields?.promotionCopy?.value),
      promotion_dates: notAvailableIfNullOrEmpty(
        formatDateForGTM(
          `${tile?.fields?.promotionDateFROM?.value} - ${tile?.fields?.promotionDateTO?.value}`
        )
      ),
      promotion_cta: notAvailableIfNullOrEmpty(tile?.fields?.cTAButtonCopy?.value),
      promotion_url: notAvailableIfNullOrEmpty(tile?.fields?.promotionURL?.value?.href),
    };
  });

  const sendGtmData = (index: number) => {
    const obj = [];
    obj?.push(gtmPromotion && gtmPromotion[index]);
    gtmPromotion &&
      trackObjectForPromotion(GTM_EVENT?.selectPromotion, storeData?.storeId, obj as Items[]);
  };

  //sending view_promotion data on page load
  useEffect(() => {
    if (storeData?.storeId && gtmPromotion) {
      trackObjectForPromotion(
        GTM_EVENT?.viewPromotion,
        storeData?.storeId,
        gtmPromotion as Items[]
      );
    }
  }, [storeData?.storeId]);

  if (eventsData?.length > 0 || (fields?.marketingTile && fields?.marketingTile?.length > 0)) {
    return (
      <section className={base()} component-data="components/ServicePanel/ServicePanel">
        <div className={eventWrapper()}>
          <RichTextHelper tag="h3" field={fields?.eventTitle} className={eventHeading()} />
          <div className={eventAndTileWrapper({ className: `lg:grid-cols-3` })}>
            {/**Events */}
            {eventsData?.length > 0 && (
              <div
                className={eventsTiles({ className: `${getEventGridCols(eventsData?.length)}` })}
              >
                {sortedEventsData?.slice(0, 3)?.map((upcomingEvent, index) => (
                  <React.Fragment key={index}>
                    {index !== 0 && <span className={eventBorder()}></span>}
                    <EventTile event={upcomingEvent} key={index} />
                  </React.Fragment>
                ))}
              </div>
            )}

            {/**Marketing Tile */}
            {fields?.marketingTile && fields?.marketingTile?.length > 0 && (
              <div
                className={marketingTiles({
                  className: `${getMarketingGridCols(eventsData?.length)}`,
                })}
              >
                {eventsData?.length < 4 &&
                  fields?.marketingTile?.slice(0, marketingTilesToShow)?.map((tile, index) => {
                    return (
                      <div
                        key={index}
                        data-creative-id={tile?.fields?.componentName?.value}
                        data-promotion-cta={tile?.fields?.cTAButtonCopy?.value}
                        data-promo-id={tile?.fields?.promotionId?.value}
                        data-promotion-name={tile?.fields?.promotionName?.value}
                        data-promotion-copy={tile?.fields?.promotionCopy?.value}
                        dta-promotion-url={tile?.fields?.promotionURL?.value?.href}
                        className="lg:max-w-[390px] w-full h-[186px] lg:h-[212px] bg-color-brand-secondary-1-base"
                        onClick={() => {
                          sendGtmData(index);
                        }}
                      >
                        <LinkHelper field={tile?.fields?.personalizedLinkUrl}>
                          <ImageHelper
                            field={tile?.fields?.personalizedImage}
                            className="lg:h-[212px] w-full h-full"
                          />
                        </LinkHelper>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      </section>
    );
  } else {
    return <></>;
  }
};

//Carousel Tile
const ServiceTile = ({
  card,
  hideInMobile,
  className,
  index,
  tileLength,
  onClick,
}: {
  card: any;
  hideInMobile?: boolean;
  className?: string;
  index: number;
  tileLength: number;
  onClick: () => void;
}) => {
  const {
    tileWrapper,
    tileDetailWrapper,
    tileHeading,
    tileSubHeading,
    tileDescription,
    tileCTA,
    tileTextWrapper,
    imgWrapper,
    icon,
    ctaWrapper,
  } = servicePanelVariants({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  return (
    <div
      className={tileWrapper({
        className: `${className} ${hideInMobile ? 'hidden' : ''} ${
          index === tileLength - 1 ? 'mr-[14px] lg:mr-0' : 'mr-0'
        }`,
      })}
      onClick={onClick}
    >
      <div className={imgWrapper()}>
        <ImageHelper field={card?.image?.jsonValue} height={400} width={400} />
      </div>
      <div className={tileDetailWrapper()}>
        <div className={tileTextWrapper()}>
          <Text tag={'h3'} field={card?.title} className={tileHeading()} />
          <RichText field={card?.subHeading} className={tileSubHeading()} />
          <RichText field={card?.description} className={tileDescription()} />
        </div>
        <div className={ctaWrapper()}>
          {card?.cta?.value?.href ? (
            <React.Fragment>
              <LinkHelper className={tileCTA()} field={card?.cta} />
              <IconHelper icon={'chevron-right'} className={icon()} />
            </React.Fragment>
          ) : (
            <span className={tileCTA()}>{card?.cta?.value?.text}</span>
          )}
        </div>
      </div>
    </div>
  );
};

//Event Tile
const EventTile = ({ event }: { event: EventInfoGql }) => {
  const { eventTile, eventTitle, eventDate, eventDataContainer } = servicePanelVariants({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  return (
    <div key={event?.title?.value} data-attr={event?.title?.value} className={eventTile()}>
      <RichTextHelper tag="span" className={eventTitle()} field={event?.title} />
      <div className={eventDataContainer()}>
        <IconHelper icon="calendar" className="block" />
        <TextHelper
          tag="span"
          className={eventDate()}
          field={{ value: event?.fromDateTime?.value?.split(' ')?.[0] }}
        />
      </div>
      {/* <div className={eventDataContainer({ className: '!mb-0' })}>
        <IconHelper icon="storeLocation" className="block" />
        <TextHelper tag="span" className={eventLocation()} field={event?.location} />
      </div> */}
    </div>
  );
};

//Default variant to show both the component (Service Panel and Upcoming Events)
export const Default = (props: ServicePanelProps): JSX.Element => {
  return <ServicePanel {...props} />;
};

//show only Service Panel carousel
export const CarouselWithTiles = (props: ServicePanelProps): JSX.Element => {
  return <ServiceCarousel {...props} />;
};

//show only Events
export const UpcomingEvents = (props: ServicePanelProps): JSX.Element => {
  return <Events {...props} />;
};

//check withDataSourceCheck If it is not then show blank instead of error.
export default withDatasourceCheck()<ServicePanelProps>(ServicePanel);
