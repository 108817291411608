import { tv } from 'tailwind-variants';

export const changeMyStoreTailwindVariant = tv(
  {
    slots: {
      base: [
        'change-my-store',
        'font-primary',
        'relative',
        'shadow-changeStore',
        'flex-col',
        'duration-[800ms]',
        'overflow-hidden',
        'ease-in-out',
      ],
      storeName: [
        'text-heading-desk-medium-bold',
        'font-heading-desk-medium-bold',
        'leading-heading-desk-medium-bold',
      ],
      storeAddress: [
        'mt-2',
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
      ],
      storeNumber: [
        'block',
        'mt-desk-global-spacing-spacing-3',
        'focus:outline-0',
        'underline',
        'text-color-brand-primary-1-base',
        'text-body-large-bold',
        'font-body-large-bold',
        'leading-body-large-bold',
      ],
      timingWrapper: ['flex', 'items-end'],
      storeTiming: [
        'mt-desk-global-spacing-spacing-3',
        'mr-1',
        'text-body-small-regular',
        'font-body-small-regular',
        'leading-body-small-regular',
      ],
      openingHour: [
        'text-body-small-regular',
        'font-body-small-regular',
        'leading-body-small-regular',
      ],
      buttonWrapper: [''],
      openText: ['text-body-small-bold', 'font-body-small-bold', 'leading-body-small-bold'],
      cta: [
        'text-heading-desk-medium-bold',
        'font-heading-desk-medium-bold',
        'leading-heading-desk-medium-bold',
        'text-color-text-brand-1',
        'border',
        'hover:no-underline',
        'hover:bg-color-brand-primary-1-base',
        'hover:text-color-text-white',
        'rounded-desk-global-radius-small',
        'border-2',
        'text-nowrap',
        'border-color-brand-primary-1-base',
      ],
      icon: [
        '[&>svg>*>*]:fill-color-brand-primary-1-base [&>svg>*]:fill-color-brand-primary-1-base',
        'absolute',
        'top-[16px]',
        'right-[16px]',
        'h-6',
        'w-6',
      ],
      changeMyStoreV2Wrapper: ['services-v2-wrapper flex flex-col'],
      changeMyStoreTitle: [
        'text-color-text-black py-desk-global-spacing-spacing-3 border-b-2 border-color-brand-primary-1-base',
      ],
      changeMyStoreLinks: [
        'py-desk-global-spacing-spacing-2 text-color-text-black text-body-large-regular font-body-large-regular leading-body-large-regular border-b-2 border-solid border-color-border-mid block',
      ],
      changeMyStoreSecondaryLinks: [
        'py-mob-padding-tight-y text-color-text-black text-body-small-regular font-body-small-regular leading-body-small-regular block',
      ],
    },
    compoundSlots: [{ slots: ['base', 'buttonWrapper', 'icon'], class: ['flex'] }],
    variants: {
      size: {
        mobile: {
          base: [
            'py-mob-padding-tight-y',
            'px-mob-padding-micro-x',
            'w-full',
            'gap-mob-space-between-tight-vertical',
            // 'duration-300 ease-in-out',
            // 'translate-y-0 animate-[topAnimation_0.3s_ease-in-out]',
          ],
          buttonWrapper: ['gap-mob-space-between-tight-vertical', 'py-mob-padding-micro-y'],
          cta: ['py-desk-component-button-full-padding-y', 'px-[20px]'],
          changeMyStoreTitle: [
            'text-heading-mob-medium-bold',
            'font-heading-mob-medium-bold',
            'leading-heading-mob-medium-bold',
          ],
        },
        desktop: {
          base: [
            'w-full',
            'max-w-[426px]',
            'rounded-desk-global-radius-medium',
            'h-full',
            'py-desk-padding-tight-y',
            'px-desk-padding-micro-x',
            'gap-desk-space-between-tight-vertical',
            'animate-none',
          ],
          buttonWrapper: ['gap-desk-space-between-tight-vertical', 'py-desk-padding-micro-y'],
          cta: ['py-mob-component-button-full-padding-y', 'px-[30px]'],
          icon: ['cursor-pointer'],
          changeMyStoreTitle: [
            'text-heading-desk-medium-bold',
            'font-heading-desk-medium-bold',
            'leading-heading-desk-medium-bold',
          ],
        },
      },
      theme: {
        psp: {},
        wnw: {
          cta: ['!text-[17px]'],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
