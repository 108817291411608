import { tv } from 'tailwind-variants';

export const shortenedContentBlockTailwindVariant = tv(
  {
    slots: {
      base: ['shortenedContentBlock', 'container', 'mx-auto'],
      title: [''],
      linkWrapper: ['w-fit', 'flex', 'items-center'],
      link: [
        'text-body-large-bold',
        'font-body-large-bold',
        'leading-body-large-bold',
        'text-color-text-brand-1',
      ],
      icon: [
        '[&>svg>*]:fill-color-text-brand-1',
        '[&>svg]:h-4',
        '[&>svg]:w-4',
        '[&>*>*]:fill-color-text-brand-1',
        'ml-mob-space-between-micro-horizontal',
      ],
      description: [
        'mt-3',
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
        '[&>h2]:mb-3',
        '[&>ul]:block',
        '[&>ul]:pl-10',
        '[&>ul]:pt-5',
        '[&>ul]:my-[10px]',
        '[&>p]:mb-[10px]',
      ],
    },
    compoundSlots: [{ slots: [], class: [] }],
    variants: {
      size: {
        mobile: {
          base: ['py-mob-padding-tight-y'],
          linkWrapper: ['mt-mob-margin-base-bottom'],
          title: [
            'font-heading-mob-large-bold text-heading-mob-large-bold leading-heading-mob-large-bold',
          ],
          description: ['mt-mob-margin-tight-bottom', '[&>h2]:mb-mob-margin-tight-bottom'],
        },
        desktop: {
          base: ['py-desk-padding-tight-y'],
          linkWrapper: [
            'cursor-pointer',
            'mt-desk-margin-base-bottom',
            '[&>*>*>*]:hover:fill-color-brand-tertiary-3-base',
            '[&>*]:hover:text-color-brand-tertiary-3-base',
            '[&>*]:hover:underline',
          ],
          title: [
            'font-heading-desk-large-bold text-heading-desk-large-bold leading-heading-desk-large-bold',
          ],
          description: ['mt-desk-margin-tight-bottom', '[&>h2]:mb-desk-margin-tight-bottom'],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
