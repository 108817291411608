//global
import React, { useState } from 'react';
import clsx from 'clsx';
//local
import { navigationTailwindVariant } from 'tailwindVariants/components/NewnavigationTailwindVariant';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
import {
  Link as JssLink,
  Text,
  TextField,
  NextImage,
  Placeholder,
  ComponentRendering,
} from '@sitecore-jss/sitecore-jss-nextjs';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
//lib
import { DPNTargetItems } from 'lib/types/header';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
//types
type DesktopNavProps = {
  item: DPNTargetItems;
  isActive: string;
  modelref?: React.LegacyRef<HTMLUListElement | undefined> | undefined;
  handleisActive: (val: string) => void;
  parentIndex?: number; //for nested navigation
  rendering: ComponentRendering;
};
export default function DesktopNav({
  item,
  isActive,
  modelref,
  handleisActive,
  parentIndex,
  rendering,
}: DesktopNavProps): JSX.Element {
  const {
    flexGrid,
    navigationOpen,
    navigationAfter,
    liWrapper,
    subMenuWrapper,
    iconWrapper,
    h4,
    navigationLink,
    bodyRegular,
    closeIcon,
    deskSubNavWrapper,
    deskSubNavHeading,
    justifyBetween,
    normalFont,
    flexCol,
    desktopHeading,
    leading39,
    desktopIcon,
    gap2,
    textBlack,
    fontMedium,
    shopAllLink,
    threeColLayout,
    levelOneCarrot,
    colLayoutOne,
    colLayoutOneParentItem,
    colLayoutTwo,
    colLayoutTopWrapper,
    colLayoutThree,
    colLayoutTwoCols,
    colLinkWrapper,
    colLinkItems,
    colLinkTitle,
    shopNavigation,
    colLayoutTwoChilds,
    colLayoutOneCategories,
  } = navigationTailwindVariant();
  const hasChildren = item?.childrens?.results && item?.childrens?.results?.length > 0;
  const isEE = useExperienceEditor();

  const [activeShopCategory, setActiveShopCategory] = useState<number | undefined>(0);
  return (
    <li
      key={item?.name}
      className={clsx(
        liWrapper(),
        item?.name !== 'New Shop' && 'relative',
        isActive === item?.name && 'bg-color-brand-primary-1-dark'
      )}
    >
      {item?.childrens?.results?.length > 0 ? (
        item?.link?.jsonValue && (
          <div className="flex items-center">
            <JssLink
              editable={false}
              role="button"
              field={item?.link?.jsonValue}
              className={clsx(
                'cursor-pointer',
                navigationLink(),
                `${isActive === item?.name ? clsx(navigationAfter()) : ''}`
              )}
              onClick={(e) => {
                hasChildren ? handleisActive(item?.name as string) : handleisActive(''),
                  e.preventDefault();
              }}
            />
            <IconHelper
              icon={'Caret'}
              className={levelOneCarrot({ className: 'ml-1' })}
              onClick={() => {
                hasChildren ? handleisActive(item?.name as string) : handleisActive('');
              }}
            />
          </div>
        )
      ) : item?.link?.jsonValue?.value?.href && item?.link?.jsonValue?.value?.href?.length > 0 ? (
        <LinkHelper
          onClick={() => {
            handleisActive('');
          }}
          editable={false}
          field={item?.link?.jsonValue}
          className={clsx(
            navigationLink(),
            `${isActive === item?.name ? clsx(navigationAfter(), textBlack()) : ''}`
          )}
        />
      ) : (
        ''
      )}
      {isActive !== item?.name ? null : (
        <ul
          data-component-section={'deskutility'}
          ref={modelref as React.LegacyRef<HTMLUListElement> | undefined}
          className={item?.isShop?.jsonValue?.value ? shopNavigation() : navigationOpen()}
        >
          <li>
            <div className={subMenuWrapper()}>
              {item?.name !== 'New Shop' && (
                <div className={iconWrapper()}>
                  <div className={leading39()}>
                    {item?.link?.jsonValue?.value?.text && (
                      <p className={desktopHeading()}>{item?.link?.jsonValue?.value?.text}</p>
                    )}
                  </div>

                  <IconHelper
                    icon={'close'}
                    className={closeIcon()}
                    onClick={() => {
                      handleisActive('');
                    }}
                  />
                </div>
              )}
              {item?.isShop?.jsonValue?.value ? (
                <div className={threeColLayout()}>
                  <div className={colLayoutOne()}>
                    {item?.childrens?.results?.map((gchild, index) => {
                      return (
                        <div
                          key={index}
                          className={clsx(
                            colLayoutOneCategories(),
                            activeShopCategory === index && 'bg-color-background-light-mid'
                          )}
                        >
                          <p
                            className={clsx(colLayoutOneParentItem())}
                            onClick={() => {
                              setActiveShopCategory(index);
                            }}
                          >
                            {gchild?.name}
                          </p>
                          <IconHelper
                            icon={'breadcrumb-down'}
                            className="[&>svg>path]:fill-color-text-dark rotate-[270deg]"
                          />
                        </div>
                      );
                    })}
                  </div>
                  <div className={colLayoutTwo()}>
                    <div className={colLayoutTopWrapper()}>
                      {item?.childrens?.results?.map((gchild, index) => {
                        if (activeShopCategory === index) {
                          return gchild?.childrens?.results?.map((gchildinner, innerindex) => (
                            <div className={colLayoutTwoCols()} key={`${index}-${innerindex}`}>
                              {gchildinner?.childrens?.results?.map((colChilds, colIndex) => (
                                <div className={colLayoutTwoChilds()} key={colIndex}>
                                  <p className={colLinkTitle()}>
                                    {colChilds?.link?.jsonValue?.value?.text}
                                  </p>
                                  <div className={colLinkWrapper()}>
                                    {colChilds?.childrens?.results?.map((linkChild, linkIndex) => (
                                      <LinkHelper
                                        key={linkIndex}
                                        className={colLinkItems()}
                                        field={linkChild?.link?.jsonValue}
                                        onClick={() => handleisActive('')}
                                      />
                                    ))}
                                  </div>
                                </div>
                              ))}
                            </div>
                          ));
                        }
                        return null; // Ensures nothing is rendered for non-matching categories
                      })}
                    </div>
                    <div className="layout-two-bottom-wrapper">
                      <Placeholder
                        name={`popular-categories-shop-desktop-${activeShopCategory}`}
                        rendering={rendering}
                      />
                    </div>
                  </div>
                  <div className={colLayoutThree()}>
                    <div
                      data-placeholder-id={`primary-nav-desktop-${activeShopCategory}`}
                      className={
                        isEE ? 'flex flex-col self-start w-full' : 'flex flex-col self-start'
                      }
                    >
                      <Placeholder
                        name={`primary-nav-shop-desktop-${activeShopCategory}`}
                        rendering={rendering}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className={flexGrid()}>
                    {item?.childrens &&
                      item?.childrens?.results?.flatMap((child, ind) => {
                        const gchildLength = item?.childrens?.results?.length;
                        return (
                          <div className={clsx(justifyBetween(), flexCol())} key={child?.name}>
                            {child?.childrens
                              ? child?.childrens?.results?.map((gchild, index) => {
                                  return (
                                    <div key={index} className={deskSubNavHeading()}>
                                      {gchild?.childrens &&
                                        !child?.hideTitle?.jsonValue?.value &&
                                        ind !== gchildLength && (
                                          <>
                                            {gchild?.icon === null && (
                                              <p className={h4()}>
                                                {gchild?.link?.jsonValue?.value?.text}

                                                <LinkHelper
                                                  className={shopAllLink()}
                                                  field={gchild?.shopall?.jsonValue}
                                                  onClick={() => {
                                                    handleisActive('');
                                                  }}
                                                ></LinkHelper>
                                              </p>
                                            )}
                                          </>
                                        )}

                                      <ul className={deskSubNavWrapper()}>
                                        {gchild?.childrens?.results &&
                                          gchild?.childrens?.results?.map((c, i) => (
                                            <li key={i} className={normalFont()}>
                                              {c?.link?.jsonValue?.value?.href &&
                                              c?.link?.jsonValue?.value?.href?.length > 0 ? (
                                                <LinkHelper
                                                  onClick={() => {
                                                    handleisActive('');
                                                  }}
                                                  editable={false}
                                                  field={c?.link?.jsonValue}
                                                  className={clsx(bodyRegular())}
                                                />
                                              ) : (
                                                ''
                                              )}
                                            </li>
                                          ))}
                                      </ul>
                                    </div>
                                  );
                                })
                              : ''}
                            {ind === gchildLength - 1 && (
                              <div
                                data-placeholder-id={`primary-nav-desktop-${parentIndex}`}
                                className={
                                  isEE
                                    ? 'flex flex-col self-start w-full'
                                    : 'flex flex-col self-start'
                                }
                              >
                                <Placeholder
                                  name={`primary-nav-desktop-${parentIndex}`}
                                  rendering={rendering}
                                />
                              </div>
                            )}
                            {/* Temp location for popular categories wrapper */}
                            <div className="popular-categories-wrapper">
                              <Placeholder
                                name={`popular-categories-desktop-${activeShopCategory}`}
                                rendering={rendering}
                              />
                            </div>
                            {/* Temp location for popular categories wrapper */}
                            {child?.childrens
                              ? child?.childrens?.results?.map((gchild, index) => {
                                  return (
                                    <div key={index} className={deskSubNavHeading()}>
                                      <ul className={deskSubNavWrapper()}>
                                        {/**Showing icons on last column */}
                                        {gchild?.icon && (
                                          <li className={normalFont()}>
                                            <div className={clsx(justifyBetween(), desktopIcon())}>
                                              <NextImage field={gchild?.icon?.jsonValue} />
                                              <div className={clsx(flexCol(), gap2())}>
                                                <Text
                                                  tag="h4"
                                                  className={fontMedium()}
                                                  field={gchild?.iconTitle as TextField}
                                                />
                                                {gchild?.link?.jsonValue?.value?.href &&
                                                gchild?.link?.jsonValue?.value?.href?.length > 0 ? (
                                                  <LinkHelper
                                                    onClick={() => {
                                                      handleisActive('');
                                                    }}
                                                    editable={false}
                                                    className={bodyRegular()}
                                                    field={gchild?.link?.jsonValue}
                                                  />
                                                ) : (
                                                  ''
                                                )}
                                              </div>
                                            </div>
                                          </li>
                                        )}
                                      </ul>
                                    </div>
                                  );
                                })
                              : ''}
                            {/* {item?.rfkID?.value &&
                        ind === gchildLength - 1 &&
                        child?.childrens?.results &&
                        child?.childrens?.results?.length <= 0 && (
                          <div className={deskSubNavHeading()}>
                            <PersionalizedHeaderBannerWidgetWithLink
                              rfkId={item?.rfkID?.value.toString()}
                            />
                          </div>
                        )} */}
                          </div>
                        );
                      })}
                  </div>
                </>
              )}
            </div>
          </li>
        </ul>
      )}
    </li>
  );
}
