import { AccordionProps } from './types';
import { MinusSVGIcon, PlusSVGIcon } from './svgIcons';
import { useEffect, useRef, useState } from 'react';
import { faqAccordionTailwindVariant } from 'tailwindVariants/components/faqAccordionTailwindVariant';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';

const Accordion = ({ faq, activeIndices, toggleAccordion }: AccordionProps) => {
  const {
    tabPanel,
    tabItem,
    tabItemSummary,
    tabItemQuestion,
    tabItemTitle,
    tabItemDescription,
    activeTabSlideDown,
    activeTabSlideIn,
  } = faqAccordionTailwindVariant({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  const [contentHeights, setContentHeights] = useState<number[]>([]);
  const contentRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const heights = contentRefs.current.map((ref) => ref?.scrollHeight || 0);
    setContentHeights(heights);
  }, [faq, activeIndices]);

  return (
    <div className={tabPanel()}>
      {faq &&
        faq.map((item, index) => (
          <div key={index} className={tabItem()}>
            <div className={tabItemSummary()} onClick={() => toggleAccordion(index)}>
              <div className={tabItemQuestion()}>
                <div>{activeIndices.includes(index) ? <MinusSVGIcon /> : <PlusSVGIcon />}</div>
                <div className={tabItemTitle()}>
                  {item.question?.value && <RichTextHelper field={item.question} />}
                </div>
              </div>
              <div
                ref={(ref) => (contentRefs.current[index] = ref)}
                className={`${tabItemDescription()} ${
                  activeIndices.includes(index) ? activeTabSlideDown() : activeTabSlideIn()
                }`}
                style={{
                  height: activeIndices.includes(index) ? `${contentHeights[index]}px` : '0',
                  transition: 'height 400ms ease',
                }}
              >
                {item.answer?.value && <RichTextHelper field={item.answer} />}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default Accordion;
