import { tv } from 'tailwind-variants';

export const warningStoreChangeTailwindVariant = tv(
  {
    slots: {
      title: 'text-color-text-black font-color-text-black',
      // link: 'py-desk-component-button-full-padding-y px-desk-component-button-full-padding-x text-color-text-white hover:text-color-text-white  hover:no-underline bg-color-brand-primary-1-base hover:bg-color-brand-primary-1-dark rounded-desk-global-radius-small',
      description: 'text-body-large-regular leading-body-large-regular',
      popUpPad: '',
      buttonWrapper: 'flex gap-desk-component-button-condensed-padding-x',
      buttonWithOutline:
        'flex w-full max-w-[310px] hover:no-underline hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark text-color-text-brand-1 justify-center items-center bg-color-background-white border-[2px] border-color-brand-primary-1-base outline-none hover:bg-color-brand-primary-1-dark hover:text-color-text-white focus:bg-color-brand-primary-1-dark focus:text-color-text-white',
      button:
        'flex w-full max-w-[310px] hover:no-underline hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark text-color-text-white justify-center items-center bg-color-brand-primary-1-base outline-none',
    },
    variants: {
      size: {
        mobile: {
          title: '',
          description: '',
          popUpPad: 'py-mob-padding-tight-y px-mob-padding-base-x',
          buttonWrapper: 'flex-col mt-mob-margin-base-top',
          button:
            'rounded-mob-global-radius-small text-heading-desk-medium-bold leading-heading-desk-medium-bold font-heading-desk-medium-bold px-mob-component-button-full-padding-x py-mob-component-button-full-padding-y gap-mob-component-button-full-space-between',
          buttonWithOutline:
            'rounded-mob-global-radius-small text-heading-desk-medium-bold leading-heading-desk-medium-bold font-heading-desk-medium-bold px-mob-component-button-full-padding-x py-mob-component-button-full-padding-y gap-mob-component-button-full-space-between',
        },
        desktop: {
          title: '',
          description: '',
          popUpPad: 'py-desk-padding-tight-y px-desk-padding-base-x',
          buttonWrapper: 'flex-row mt-desk-margin-base-top',
          button:
            'rounded-desk-global-radius-small text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold px-desk-component-button-full-padding-x py-desk-component-button-full-padding-y gap-desk-component-button-full-space-between',
          buttonWithOutline:
            'rounded-desk-global-radius-small text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold px-desk-component-button-full-padding-x py-desk-component-button-full-padding-y gap-desk-component-button-full-space-between',
        },
      },
    },
  },
  {
    responsiveVariants: ['sm', 'lg'],
  }
);
