import { Field, Text, LinkFieldValue } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import React, { useContext, useState } from 'react';
import { useTheme } from 'lib/context/ThemeContext';
import {
  // Field as FormikField,
  Form as FormikForm,
  Formik,
  FormikValues,
  FormikHelpers,
} from 'formik';
import TextField from 'src/helpers/Form/TextField';
import { FormFieldsProps, transformData } from 'src/utils/formUtils';
import clsx from 'clsx';
import ComponentContext from 'lib/context/ComponentContext';
import { apiRequest } from 'src/utils/apiWrapper';
import { useAuthToken } from 'src/helpers/MiddlewareToken';
import { ErrorCodes } from 'src/helpers/Constants';
import ModalWrapper from 'src/helpers/commonComponents/ModalWrapper';
import { forgotPasswordTailwindVariant } from 'tailwindVariants/components/forgotPasswordTailwindVariant';
import { cookieModalTailwindVariant } from 'tailwindVariants/components/cookieModalTailwindVariant';
import { loginAPI } from 'src/utils/nextApiConfig';
import { useRouter } from 'next/router';

export type ForgotPasswordFormProps = React.InputHTMLAttributes<HTMLInputElement> &
  ComponentProps & {
    fields: {
      data: {
        data: {
          title: Field<string>;
          shortDescription: Field<string>;
          submitButtonText?: Field<string>;
          cancelButtonText?: Field<string>;
          closeButtonText?: Field<string>;
          successMessage?: Field<string>;
          failureMessage: Field<string>;
          successRedirectUrl: LinkFieldValue;
          formFields: {
            targetItems: Array<FormFieldsProps>;
          };
          errors: ErrorField;
        };
      };
    };
  };
interface ErrorField {
  values: {
    name: string;
    value: string;
  }[];
}
interface FormValuesTypes {
  Email: string;
}
const ForgotPasswordForm = ({ fields, params }: ForgotPasswordFormProps): JSX.Element => {
  const { themeName, themeNameUpper } = useTheme();
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const token = useAuthToken();

  const {
    form,
    fieldWrap,
    // credentialsWrapper,
  } = forgotPasswordTailwindVariant({
    site: themeName,
    size: {
      initial: 'mobile',
      md: 'desktop',
    },
  });

  const { modalHeading, modalDisclaimer, modalTitle, modalButton, buttonWrapper, formWrapper } =
    cookieModalTailwindVariant({
      size: {
        initial: 'mobile',
        md: 'desktop',
      },
    });
  const router = useRouter();

  // API to cover the ForgotPassword functionality:
  const forForgotPassword = async (values: FormikValues) => {
    // API for forgot password:
    const emailInput = values?.Email;

    // Page redirect to Return Url
    const returnUrl = router?.query?.ReturnUrl || router?.query?.returnurl;

    const headersData = {
      site: themeNameUpper,
      requesturl: window.location.origin,
      Authorization: token,
    };
    let returnURl = `ReturnUrl=${returnUrl}`;
    const { favoritesku } = router.query;
    if (favoritesku) {
      returnURl = returnURl + '&favoritesku=' + favoritesku;
    } else if (componentContextData.productIdToAddInWishlistAfterLogin) {
      returnURl =
        'ReturnUrl=' +
        router.asPath +
        '&favoritesku=' +
        componentContextData.productIdToAddInWishlistAfterLogin;
    }
    const postData = {
      Email: emailInput,
      ...(returnURl && { returnUrl: returnURl }), // Add returnUrl if it exists
    };
    const options = {
      method: 'POST',
      headers: headersData,
      data: postData,
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const res = await apiRequest(loginAPI?.forgotPasswordAPI, options);
    setFinishPasswordSubmit(true);
    setAfterSubmit(true);
    if (res == ErrorCodes?.NotVerifiedUser) {
      setHasError(true);
    }
  };
  const initialForgotPasswordValues = {
    Email: '',
  };
  const [finishPasswordSubmit, setFinishPasswordSubmit] = useState(true);
  const [afterSubmit, setAfterSubmit] = useState(false);
  const [hasError, setHasError] = useState(false);

  const transFormFields = transformData(fields?.data?.data?.formFields);

  // handle modal Close functionality:
  const handleModalClose = () => {
    setcomponentContextData({ ...componentContextData, ToggleForgotPasswordForm: false });
    setAfterSubmit(false);
    document.body.classList.remove('modal-open');
  };
  //return empty fregment if there is no data
  if (fields === undefined || fields === null) return <></>;
  return (
    <div className={'forgotPasswordForm ' + params?.Styles}>
      {componentContextData && componentContextData.ToggleForgotPasswordForm && (
        <ModalWrapper
          showCloseButtonInModalHeader={false}
          closeModalOnOverlayClick={true}
          onCloseClick={handleModalClose}
          showModal={componentContextData.ToggleForgotPasswordForm}
          customPopup={true}
          popupSpacing="py-mob-padding-tight-y px-mob-padding-tight-x lg:py-desk-padding-tight-y lg:px-desk-padding-tight-x shadow-privacyShadow modal-open"
          additionalClassForPopUpModal={`${
            params?.Styles ?? ''
          } !item-start !h-fit translate-y-0  animate-[topAnimation_0.3s_ease-in-out]`}
        >
          <div className={formWrapper()}>
            <div className={modalHeading()}>
              <Text tag="h6" className={modalTitle()} field={fields?.data?.data?.title} />
              {!afterSubmit && (
                <Text
                  tag="p"
                  className={modalDisclaimer()}
                  field={fields?.data?.data?.shortDescription}
                />
              )}
            </div>
            <Formik
              initialValues={initialForgotPasswordValues}
              onSubmit={(
                values: FormValuesTypes,
                { setSubmitting }: FormikHelpers<FormValuesTypes>
              ) => {
                setFinishPasswordSubmit(false);
                forForgotPassword(values);
                setSubmitting(false);
              }}
            >
              <FormikForm className={form()}>
                {!afterSubmit && (
                  <div className={fieldWrap()}>
                    <TextField {...transFormFields.Email} />
                  </div>
                )}
                {afterSubmit && (
                  <Text
                    tag="p"
                    className={modalDisclaimer()}
                    field={
                      !hasError
                        ? fields?.data?.data?.successMessage
                        : fields?.data?.data?.failureMessage
                    }
                  />
                )}
                <div className={clsx(buttonWrapper(), '!justify-between', 'gap-[24px]')}>
                  {!afterSubmit && (
                    <button
                      aria-label="submit"
                      type="submit"
                      className={clsx(
                        modalButton({
                          buttonOutline: false,
                          size: 'desktop',
                          className: '!px-[15px]',
                        }),
                        'w-full'
                      )}
                    >
                      {!finishPasswordSubmit && 'Loading...'}
                      {finishPasswordSubmit && fields?.data?.data?.submitButtonText?.value}
                    </button>
                  )}
                  {fields?.data?.data?.cancelButtonText?.value && (
                    <button
                      aria-label="close"
                      className={clsx(
                        modalButton({
                          buttonOutline: afterSubmit ? false : true,
                          size: 'desktop',
                          className: '!px-[15px]',
                        }),
                        'w-full'
                      )}
                      onClick={handleModalClose}
                    >
                      {!afterSubmit && fields?.data?.data?.cancelButtonText?.value}
                      {afterSubmit && 'Close'}
                    </button>
                  )}
                </div>
                {/* )} */}
              </FormikForm>
            </Formik>
          </div>
        </ModalWrapper>
      )}
    </div>
  );
};
export default ForgotPasswordForm;
